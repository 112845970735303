import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined
    spin
  />
);

const Spinner = ({size = "default", style}) => {
  return (<Spin indicator={antIcon} size={size} style={{color: '#0062c5', ...style}} />);
};
export default Spinner;
