import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";
import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import _map from "lodash/map";
import ServiceTile from "../../ui/ServiceTile/ServiceTile";
import { services } from "./Homepage.helper";
import { fetchServiceCategories } from "../../redux/actions/service";
import withUserData from "../../HOC/withUserData";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";
import SpHomeHeroImage from "../../sp-components/SpHomeHeroImage/SpHomeHeroImage";
import { motion } from 'framer-motion';
import useCustomNavigate from "../../hooks/useCustomNavigate";
import { ProvidersSection } from "../AboutUs/AboutUs.helper";
import { resetTransactionFormData } from "../../redux/actions/transactions";


function Homepage({ isLoggedIn }) {
  const dispatch = useDispatch();
  const { navigate, clearRedirect } = useCustomNavigate();

  const { data: categories, loading: categoriesLoading } = useSelector(state => state.service.categories);

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(null);
  const [serviceList, setServiceList] = useState(services);


  const wordExists = useCallback((key, service) => {
    const regex = new RegExp(key, "i");
    return regex.test(service);
  }, []);

  useEffect(() => {
    dispatch(fetchServiceCategories());
    clearRedirect();
  }, []);

  useEffect(() => {
    if (value) {
      setServiceList(
        serviceList.filter(({ label }) => wordExists(value, label))
      );
      return;
    }
    if (value === "") {
      setServiceList(services);
    }
  }, [value, serviceList, wordExists]);

  const handleServiceClick = useCallback(
    (item, i) => {
      const { label, url, status } = item;

      if (status.toLowerCase() !== "active") {
        return;
      }

      dispatch(resetTransactionFormData());

      if (isLoggedIn) {
        navigate(url);
        return;
      }

      navigate('/login', {}, url);
      return;
    },
    [dispatch, navigate, isLoggedIn]
  );

  return (
    <SpPageTransition>
      <div className="homepage">
        <Row className="homepage__container">
          <Col className="homepage__container__row-one" span={12} lg={12} xs={24}>
            <Content>
              {/* <h2 className="homepage__title">All the bills you need to pay</h2> */}
              <h4 className="homepage__title">
                Pay utility bills instantly with Lceepay – secure and stress-free!
              </h4>

              <Row gutter={[20, 20]} className="service_tile__wrapper">

                {_map(
                  categories,
                  ({ name: label, image_url: icon, bg_color: bgColor, slug: url, status }, i) => (
                    <ServiceTile
                      key={i}
                      label={label}
                      icon={icon}
                      url={url}
                      bgColor={bgColor}
                      onClick={() => handleServiceClick({ label, url, status }, i)}
                      isActive={status.toLowerCase() === 'active'}
                    />
                  )
                )}
              </Row>
              
            </Content>
          </Col>
          <Col
            className="homepage__container__row-two"
            span={12}
            lg={12}
            sm={12}
            xs={24}
          >
            <Content className="homepage_content__img_wrapper" >
              <motion.div
                initial={{ opacity: 0 }}  // Start with opacity 0 (completely invisible)
                animate={{ opacity: 1 }}  // Animate to opacity 1 (fully visible)
                transition={{ duration: 1.5 }}  // Duration of the fade-in effect
              >
                <SpHomeHeroImage />
              </motion.div>
            </Content>
          </Col>    
        </Row>
        <div style={{
          marginBottom: 64, 
          backgroundColor: '#0062c5', 
          padding: "4rem 0rem",
          display: 'flex',
          justifyContent: 'center'
        }}>
          <div>
            <ProvidersSection headingColor="#f6f9ff" />
          </div>
        </div>
      </div>
    </SpPageTransition>
  );
}

export default withUserData(Homepage);
